import { NavLink } from 'react-router-dom'
import logo from '../../assets/image/logo/cg-on-dark-full-logo_2.png'
import { Flex } from '../../style'
import StyledContainer from '../../style/styledContainer'

export default ({children}) => {
    return (
        <Flex alignItems='center'>
            <NavLink to='/'>
                <img style={{width: '196px'}} src={logo}></img>
            </NavLink>
            {children}
        </Flex>
    )
}