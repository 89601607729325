import { useEffect, useState } from 'react';
import Video from '../component/Video'
import { message, Tooltip } from 'antd'
import {
    StyledLink,
    Absolute,
    basicBlack,
    basicBlue,
    basicGrey,
    basicWhite,
    Center,
    Column,
    Row,
    darkBlue,
    Flex,
    FullScreenSection,
    FullScreenWidthSection,
    seniorGrey,
    ShadowedText,
    StyledA,
    StyledImg,
    Text,
    StyledButton,
    lightYellow,
    user, User,
    TextFs10
} from '../style';
import ScreenTitle from '../component/ScreenTitle';
import ScrollDownIcon from '../component/ScrollDownIcon/ScrollDownIcon';
import TopNav from '../component/TopNav';
import { HiArrowRight } from 'react-icons/hi'
import StyledContainer from '../style/styledContainer';
import earth from '../assets/image/earth.svg'
import styled from 'styled-components'
import ChainSupport from '../component/ChainSupport';
import PhoneScroll from '../component/PhoneScroll';
import illustration from '../assets/image/illustrations.svg'
import { Controller, Scene } from 'react-scrollmagic';
import ArticleScroll from '../component/ArticleScroll';
import orange from '../assets/image/orange.svg'
import green from '../assets/image/green.svg'
import { Parallax, useController } from 'react-scroll-parallax';
import { BsFillPlayCircleFill } from 'react-icons/bs'
import Download from '../component/Download';
import usdt from '../assets/image/usdt.png'
import chng from '../assets/image/chng.svg'
import copy from '../assets/image/copy.svg'
import {getLuckyWinnersSeptember} from '../server/index'
import { Code } from 'react-feather';


const HoveredTopNav = styled(TopNav)`
    #right-arrow{
        transition: all 1s linear;
    }
    &::hover #right-arrow{
        transform: translateX(20px);
    }
`

const ecosystem = [
    {
        prefix: 'Chain-hop',
        title: 'in an instant',
        des: 'Using our cros-chain tech'
    },
    {
        prefix: 'Earn',
        title: 'up to 12.28% APY',
        des: 'On stables'
    },
    {
        prefix: 'No',
        title: 'impermanent loss',
        des: 'On futures liquidity pools'
    },
    {
        prefix: 'Master',
        title: 'derivatives',
        des: 'Both futures and options'
    }
]

const _list = [
    {
        order: '1',
        username: "jimAAAAAAAAA",
        address: '0x00...0000',
        volume: '$200,000.1'
    },
    {
        order: '2',
        username: "jimAAAAAAAAA",
        address: '0x00...0000',
        volume: '$200,000.1'
    },
    {
        order: '3',
        username: "jim",
        address: '0x00...0000',
        volume: '$200,000.1'
    },
    {
        order: '4',
        username: "jim",
        address: '0x00...0000',
        volume: '$200,000.1'
    },
]

const ColorHover = styled(StyledContainer)`
&:hover {
    color: #4c5fef;
}
`
const OneRowEllipsis = styled(StyledContainer)`
text-overflow: ellipsis;
white-space: nowrap;
overflow: hidden;
`

function toThousands(num) {
    var result = [ ], counter = 0;
    num = (num || 0).toString().split('');
    for (var i = num.length - 1; i >= 0; i--) {
        counter++;
        result.unshift(num[i]);
        if (!(counter % 3) && i != 0) { result.unshift(','); }
    }
    return result.join('');
}

const CustomPagination = ({totalData, onChange, pageSize = 10, current = 1}) => {
    const [pages, setPages] = useState(0)
    const [pageNode, setPageNode] = useState([])
     
    useEffect(() => {
        const total = totalData.length
        const tempPages = Math.ceil( total / pageSize)
        const pageItemNodes = []
        for(let i = 1; i<=tempPages; i++) {
            pageItemNodes.push(<ColorHover key={i} onClick={() => {
                onChange(i)
            }} pointer fontSize="12px" lineHeight="1" color={ current === i ? '#4c5fef' : '#121217' } mr="5px" fontFamily="LFTEtica" borderRadius="4px" background="#fff" p="8px 7px" boxShadow="0 2px 4px 0 rgba(45, 45, 61, 0.1)">
            {i}
            </ColorHover>)
        }
        setPageNode(pageItemNodes)
        setPages(tempPages)
    }, [totalData, current])


    return (
        <>
            {
                pages > 1 ?  pageNode : ''
            }
        </>
    )
}

export default () => {
    const { parallaxController } = useController()
    const pageSize = 10

    const [current, setCurrent] = useState(1)
    const [allData, setAllData] = useState([])
    const [list, setList] = useState([]);

    useEffect(() => {
        function getList() {
            getLuckyWinnersSeptember().then(res =>{
                const { status, data=null} = res
                if(status === 200) {
                    if(data.code === 200) {
                        const tempData = data.data.luckyWinners || []
                        const l = tempData.map(val => {
                            const addressLeft = val.address.substr(0,7)
                            const addressRight = val.address.substr(-7,7)
                            val.displayAddress = addressLeft + '...' + addressRight
                            return val
                        })
                        setAllData(l)
                    }
                }
           })
        }
        getList()
        const timer = setInterval(()=> {
            getList()
        }, 30000)

        return () => {
            // 清理 定时器
            timer && clearInterval(timer)
        }
    }, []);

    useEffect(() => {
        const offset = (current - 1) * pageSize
        setList(allData.slice(offset, offset + pageSize))
    }, [current, allData])

    const onImageLoad = () => {
        parallaxController.update();
    }

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text).then(()=>{
            message.success('copied!')
        })
    } 

    const fnCurPage = (curPage) => {
        setCurrent(curPage)
    }
    

    return (
        <FullScreenWidthSection overflow='hidden'>
            <FullScreenSection color={basicWhite} overflow='hidden' position='relative' p='15px' boxSizing='border-box'>
                <Absolute top='65px' left='70px' zIndex='1'>
                    <HoveredTopNav pointer>
                        <StyledA target='_blank' href='https://chainge-finance.medium.com/the-chainge-brand-upgrade-welcome-to-your-new-home-of-defi-c530d312f2bb'>
                            <Flex
                                hover={`background: ${lightYellow}; color: #000;`}
                                color='#fff'
                                height='30px' borderRadius='17px' alignItems='center' background={basicBlack} p='8px 15px' ml='25px'>
                                <Text
                                    flex alignItems='center' justifyContent='center' mr='16px' height='100%' fontFamily='LFTEtica' fontSize='14px'>We’ve updated our brand</Text>
                                <HiArrowRight id='right-arrow' size='18px'></HiArrowRight>
                            </Flex>
                        </StyledA>
                    </HoveredTopNav>
                </Absolute>
                <Absolute right='135px' top='75px' zIndex='1'>
                    <Flex alignItems='center'>
                        <a href='https://hub.chainge.finance/info/assets' target='_blank' style={{ marginRight: '30px', color: '#fff' }}>
                            <Text fontFamily='LFTEtica' bold>Market</Text>
                        </a>
                        <StyledA href='https://hub.chainge.finance/'>
                            <Text
                                hover={`background: ${lightYellow}; color: #000;`}
                                color='#fff'
                                flex alignItems='center' justifyContent='center' height='30px' fontFamily='LFTEtica' borderRadius='17px' background={basicBlack} p='8px 15px'>Go to the old website</Text>
                        </StyledA>
                    </Flex>
                </Absolute>
                <Absolute top='0' left='0' right='0' bottom='0' p='15px 15px 0'>
                    <Video></Video>
                </Absolute>
                <Center overflowX='hidden'>
                    <ScreenTitle></ScreenTitle>
                </Center>
                <Absolute left='50%' bottom='40px' transform='translate(-50%, 0)'>
                    <ScrollDownIcon></ScrollDownIcon>
                </Absolute>
            </FullScreenSection>

            {/* height="1130px" */}
            {/* <FullScreenWidthSection position='relative' pb="59px" background="#f0f3f9">
                <Row pt="30px" justifyContent="center">
                    <Column width="316px" mt="20px">
                        <StyledContainer width="316px" height="44px" background="#ffda00" fontSize="22px" bold color="#121217" fontFamily="LFTEtica-Bold" textAlign="center" lineHeight="44px" borderRadius="5px">Chainge Trading Competition</StyledContainer>
                        <Column mt="25px">
                            <Row><Text fontSize="14px" color="#121217" fontFamily="LFTEticaSb">From:</Text> <Text margin="0px 9px" color="#121217"  fontSize="14px" fontFamily="LFTEtica">1 September 2022 12:00 (GMT+8)</Text></Row>
                            <Row><Text fontSize="14px" color="#121217" fontFamily="LFTEticaSb">Until:</Text><Text margin="0px 9px" color="#121217"  fontSize="14px" fontFamily="LFTEtica">30 September 2022 23:59 (GMT+8)</Text></Row>
                        </Column>

                        <Text margin="25px 0px 0px 0px" color="#121217" fontSize="16px" fontFamily="LFTEticaSb" textAlign="start">Swap ANY token on ANY chain in the Chainge aggregated DEX (except stable coin to stable coin)</Text>

                        <Column mt="30px">
                            <StyledContainer height="70px" background="#fff" borderRadius="12px" p="15px" boxShadow="0 2px 4px 0 rgba(45, 45, 61, 0.1)">
                                <Row>
                                    <StyledImg width='40px' height="40px" src={usdt}></StyledImg>
                                    <Column margin="0px 0px 0px 14px" alignItems="flex-start">
                                        <Text color="#121217" fontSize="16px" fontFamily="LFTEtica-Bold">$50,000 $TF-USDT</Text>
                                        <Text fontSize="14px"  fontFamily="LFTEtica" fontWeight= "normal">to be distributed</Text>
                                    </Column>
                                </Row>
                            </StyledContainer>
                        </Column>
                        
                        <Column mt="10px">
                            <StyledContainer height="56px" background="#f8fafe" borderRadius="12px 12px 0px 0px" mb="1px" p="12px 15px">
                                <Column alignItems="flex-start">
                                    <TextFs10 color="#95a3be" ml="-4px" fontFamily="LFTEtica">First place</TextFs10>
                                    <Row justifyContent="space-between" mt="6px" width="100%">
                                        <Text color="#121217" fontSize="12px" fontFamily="LFTEticaSb" letterSpacing="-0.3px">$7,800 $TF-USDT</Text>
                                        <Column alignItems="flex-end">
                                            <TextFs10 color="#121217" fontFamily="LFTEtica" mr="-13px" fontWeight="normal">User with the lowest hash #</TextFs10>
                                        </Column>
                                    </Row>
                                </Column>
                            </StyledContainer>
                            <StyledContainer height="56px" background="#f8fafe"  mb="1px" p="12px 15px">
                                <Column alignItems="flex-start">
                                    <TextFs10 color="#95a3be" ml="-4px" fontFamily="LFTEtica">Second place</TextFs10>
                                    <Row justifyContent="space-between" mt="6px" width="100%">
                                        <Text color="#121217" fontSize="12px" fontFamily="LFTEticaSb" letterSpacing="-0.3px">$3,000 $TF-USDT</Text>
                                        <Column alignItems="flex-end">
                                            <TextFs10 color="#121217" fontFamily="LFTEtica" mr="-13px" fontWeight="normal">User with the 2nd lowest hash #</TextFs10>
                                        </Column>
                                    </Row>
                                </Column>
                            </StyledContainer>
                            <StyledContainer height="56px" background="#f8fafe"  mb="1px" p="12px 15px">
                                <Column alignItems="flex-start">
                                    <TextFs10 color="#95a3be" ml="-4px" fontFamily="LFTEtica">Third place</TextFs10>
                                    <Row justifyContent="space-between" mt="6px" width="100%">
                                        <Text color="#121217" fontSize="12px" fontFamily="LFTEticaSb" letterSpacing="-0.3px">$1,400 $TF-USDT</Text>
                                        <Column alignItems="flex-end">
                                            <TextFs10 color="#121217" fontFamily="LFTEtica" mr="-13px" fontWeight="normal">User with the 3rd lowest hash #</TextFs10>
                                        </Column>
                                    </Row>
                                </Column>
                            </StyledContainer>
                            <StyledContainer height="56px" background="#f8fafe" borderRadius="0 0 12px 12px" p="12px 15px">
                                <Column alignItems="flex-start">
                                    <TextFs10 color="#95a3be" ml="-4px" fontFamily="LFTEtica">Place 4-66</TextFs10>
                                    <Row justifyContent="space-between" mt="6px" width="100%">
                                        <Text color="#121217" fontSize="12px" fontFamily="LFTEticaSb" letterSpacing="-0.3px">$600 $TF-USDT</Text>
                                        <Column alignItems="flex-end" position="relative" top="-9px">
                                            <TextFs10 color="#121217" fontFamily="LFTEtica" mr="-9px" fontWeight="normal">Users with subsequently</TextFs10>
                                            <TextFs10 color="#121217" fontFamily="LFTEtica" mr="-9px" fontWeight="normal">lowest hash numbers</TextFs10>
                                        </Column>
                                    </Row>
                                </Column>
                            </StyledContainer>
                        </Column>
                    </Column>
                    <StyledContainer height="664px" width="1px" borderLeft="1px dashed #bfcae0" margin="0px 50px" ></StyledContainer>
                    <Column width="570px"  mt="20px">
                        <Text color="#121217" fontFamily="LFTEtica-Bold" fontWeight="bold" fontSize="16px" flex mb="30px">{allData.length} lucky traders</Text>
                        <Row justifyContent="space-between" height="24px" pl="20px" pr="20px">
                            <Row><Text color="#95a3be" fontFamily="LFTEtica" fontWeight="bold" letterSpacing="-0.3px"># </Text> <Text ml="14px" color="#95a3be" fontFamily="LFTEtica" fontWeight="bold" letterSpacing="-0.3px">Username</Text></Row>
                            <Text color="#95a3be" fontFamily="LFTEtica" fontWeight="bold" letterSpacing="-0.3px">Address</Text>
                            <Text color="#95a3be" fontFamily="LFTEtica" fontWeight="bold" letterSpacing="-0.3px">Total value traded</Text>
                        </Row>
                        
                        <StyledContainer height="490px">
                            {
                                list.map((val, key) => (
                                    <Row key={key} justifyContent="space-between" height="44px" background={(key + ((current - 1 )* pageSize)) <= 2 ? '#fff'  : '#f8fafe'} borderRadius="5px" lineHeight="44px" pl="20px" pr="20px" mt="5px">
                                        <StyledContainer flex alignItems="center" width="180px"> <StyledContainer  fontFamily="LFTEticaSb" lineHeight="44px" mr="14px" >{(key + ((current - 1 )* pageSize)) +1}.</StyledContainer> <Tooltip title={val.username}><OneRowEllipsis textAlign="left" fontFamily="LFTEticaSb" fontSize="14px" lineHeight="1" width="150px">{val.username}</OneRowEllipsis></Tooltip></StyledContainer>
                                        <StyledContainer color="#121217" fontFamily="LFTEtica" fontSize="14px" width="200px" flex justifyContent="space-evenly" alignItems="center">{val.displayAddress} <StyledImg style={{cursor: 'pointer'}} margin="0px 0px 0px 12px" width='14' height="14px" src={copy} onClick={
                                            ()=> {
                                                handleCopy(val.address)
                                            }
                                        }></StyledImg></StyledContainer>
                                        <StyledContainer width="100px" color="#121217" fontFamily="LFTEticaSb" fontSize="14px" justifyContent="end" flex>${val.volume.toLocaleString('en-US')}</StyledContainer>
                                    </Row>
                                ))
                            }
                        </StyledContainer>
                        
                        <Row mt="20px" justifyContent="center">
                            <CustomPagination onChange={fnCurPage} current={current} pageSize={pageSize} totalData={allData}></CustomPagination>
                        </Row>
                    </Column>
                </Row>
            </FullScreenWidthSection> */}


            <FullScreenWidthSection pt='70px' position='relative'>
                {/* <Absolute top='0' left='20%'>
                    <Parallax className="custom-class" y={[-20, 20]} >
                        <StyledImg src={orange}></StyledImg>
                    </Parallax>
                </Absolute>
                <Absolute top='0' right='20%'>
                    <Parallax className="custom-class" y={[-20, 20]} >
                        <StyledImg src={green}></StyledImg>
                    </Parallax>
                </Absolute> */}

                <StyledImg width='333px' src={earth}></StyledImg>

                <Flex justifyContent='center'>
                    <StyledContainer mr='59px'>
                        <Parallax className="custom-class" y={[50, 150]} >
                            <StyledImg onLoad={onImageLoad} width='92px' src={orange}></StyledImg>
                        </Parallax>
                    </StyledContainer>
                    <ShadowedText mt='60px' content='crosschain'>
                        <Column>
                            <Text lineHeight='1' color={basicBlack} fontSize='32px' fontFamily='LFTEticaLt'>EXPLORE YOUR VERY OWN</Text>
                            <Text mt='10px' mb='10px' lineHeight='1' color={basicBlue} fontSize='50px' fontFamily='LFTEticaXB'>HOME OF DEFI</Text>
                            <Text lineHeight='1' color={basicBlack} fontSize='20px' fontFamily='LFTEticaSb'>POWERED BY CROSS-CHAIN MAGIC</Text>
                            <StyledContainer width='100%' flex justifyContent='center' alignItems='center'>
                                <StyledContainer m='30px 0' height='5px' width='100px' background='#242732'></StyledContainer>
                            </StyledContainer>
                            <Text fontSize='16px' width='100%' maxWidth='468px' m='auto' fontFamily='LFTEtica'>
                                When it comes to cross-chain, Chainge is the leading authority in the DeFi space. With 50 integrated chains and counting, the app supports over 1800 assets so that users can maximise their wealth’s potential and enjoy a secure, flexible & worry-free financial life.
                            </Text>

                            <StyledButton width='198px' m='30px auto 0'
                                hover={`background:${basicBlue}`}
                            >
                                <StyledA background='tranparent' href='https://youtu.be/lhg_qbXYNSQ' target='_blank'>
                                    <StyledContainer color='#fff' flex alignItems='center'>
                                        <BsFillPlayCircleFill></BsFillPlayCircleFill>
                                        <Text fontFamily='LFTEticaSb' ml='10px'> Welcome to Chainge</Text>
                                    </StyledContainer>
                                </StyledA>
                            </StyledButton>
                        </Column>
                    </ShadowedText>
                    <StyledContainer ml='60px'>
                        <Parallax className="custom-class" y={[0, 120]} >
                            <StyledImg onLoad={onImageLoad} width='103px' src={green}></StyledImg>
                        </Parallax>
                    </StyledContainer>
                </Flex>
            </FullScreenWidthSection>

            <StyledContainer mt='50px' mb='70px'>
                <ChainSupport></ChainSupport>
            </StyledContainer>

            <FullScreenWidthSection color={basicBlack}>
                <Text fontFamily='LFTEticaXB' fontSize='36px'>Designed for people like you.</Text>
                <Text fontFamily='LFTEticaXB' fontSize='36px' mt='10px'>Engineered for the opportunities you need.</Text>
                <StyledContainer mt='50px' mb='27px'>
                    <PhoneScroll></PhoneScroll>
                </StyledContainer>
                <Text fontSize='16px' width='100%' maxWidth='468px' m='auto' fontFamily='LFTEtica'>
                    Chainge’s unwavering objective is and will remain improving people’s quality of life. So, no matter who you are or where you’re from, you can freely benefit from our wide range of decentralised financial solutions neatly packed up in a flawless UX.
                </Text>
                {/* <StyledContainer mt='50px' mb='70px'>
                    <StyledImg src={illustration}></StyledImg>
                </StyledContainer> */}
            </FullScreenWidthSection>

            <FullScreenWidthSection>
                <StyledContainer mb='150px' mt='70px'>
                    <Download></Download>
                </StyledContainer>
            </FullScreenWidthSection>

            <FullScreenWidthSection>
                <Text m='auto' maxWidth='1170px' width='100%' fontSize='70px' color={basicBlack}>the next generation DeFi ecosystem. powered by Chainge.</Text>
                <StyledContainer maxWidth='1190px' width='100%' m='70px auto 100px' flex flexWrap='wrap' justifyContent='center'>
                    {
                        ecosystem.map(i => (
                            <StyledContainer borderLeft={`2px dashed ${seniorGrey}`} p='0 41px 0 25px' alignItems='start' color={basicBlack} key={i?.title} flex column>
                                <Text lineHeight='1' fontFamily='LFTEtica' fontSize='18px'>{i?.prefix}</Text>
                                <Text lineHeight='1' mt='5px' mb='10px' fontFamily='LFTEticaXB' fontSize='22px'>{i?.title}</Text>
                                <Text lineHeight='1' fontFamily='LFTEtica' fontSize='14px' >{i?.des}</Text>
                            </StyledContainer>
                        ))
                    }
                </StyledContainer>
            </FullScreenWidthSection>

            <FullScreenWidthSection id='scroll-article' background={basicBlue}>
                <StyledContainer pb='100px' pt='60px' maxWidth='950px' pl='20px' m='auto'>
                    <ArticleScroll></ArticleScroll>
                </StyledContainer>
            </FullScreenWidthSection>

        </FullScreenWidthSection>
    )
}