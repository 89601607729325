import StyledContainer from "../../style/styledContainer"
import networkHands from "../../assets/image/network-hands.svg"
import { Absolute, StyledImg } from "../../style"
import phoneTemplate from "../../assets/image/phone-mockup@3x.png"

export default () => {
    return (
        <StyledContainer position='relative'>
            <StyledImg position='relative' zIndex='2' src={phoneTemplate} width='245px'></StyledImg>
            <Absolute maxWidth='810px' width='100%' zIndex='-1' top='50%' left='50%' transform='translate(-49%, -48%)'>
                <StyledImg src={networkHands}></StyledImg>
            </Absolute>
        </StyledContainer>
    )
}