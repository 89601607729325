import React from 'react';
import styled, { css } from "styled-components";

const Container = styled.div`
      ${({ rubik }) => rubik ? css`font-family: Rubik;` : null} 
      ${({ rubikMedium }) => rubikMedium ? css`font-family: RubikMedium;` : null} 
      ${({ rubikBold }) => rubikBold ? css`font-family: RubikBold;` : null} 
      ${({ fontSize }) => fontSize ? css`font-size: ${fontSize};` : null} 
      ${({ fontFamily }) => fontFamily ? css`font-family: ${fontFamily};` : `font-family: LFTEticaLt;`} 
      ${({ fontWeight }) => fontWeight ? css`font-weight: ${fontWeight};` : null} 
      ${({ bold }) => bold ? css`font-weight: bold;` : null} 
      ${({ background }) => background ? css`background: ${background};` : null} 
      ${({ backgroundColor }) => backgroundColor ? css`background-color: ${backgroundColor};` : null} 
      ${({ backgroundImage }) => backgroundImage ? css`background-image: ${backgroundImage};` : null} 
      ${({ backgroundSize }) => backgroundSize ? css`background-size: ${backgroundSize};` : null} 
      ${({ backgroundPosition }) => backgroundPosition ? css`background-position: ${backgroundPosition};` : null} 
      ${({ filter }) => filter ? css`filter: ${filter};` : null} 
      ${({ backdropFilter }) => backdropFilter ? css`backdrop-filter: ${backdropFilter};` : null} 
      ${({ mt }) => mt ? css`margin-top: ${mt};` : null} 
      ${({ mb }) => mb ? css`margin-bottom: ${mb};` : null} 
      ${({ ml }) => ml ? css`margin-left: ${ml};` : null} 
      ${({ mr }) => mr ? css`margin-right: ${mr};` : null} 
      ${({ pt }) => pt ? css`padding-top: ${pt};` : null} 
      ${({ pb }) => pb ? css`padding-bottom: ${pb};` : null} 
      ${({ pl }) => pl ? css`padding-left: ${pl};` : null} 
      ${({ pr }) => pr ? css`padding-right: ${pr};` : null} 
      ${({ boxShadow }) => boxShadow ? css`box-shadow: ${boxShadow};` : null} 
      ${({ flex }) => flex ? css`display: flex;` : null} 
      ${({ flexDirection }) => flexDirection ? css`flex-direction: ${flexDirection};` : null} 
      ${({ flexWrap }) => flexWrap ? css`flex-wrap: ${flexWrap};` : null} 
      ${({ grid }) => grid ? css`display: grid;` : null} 
      ${({ wrap }) => wrap ? css`flex-wrap: wrap;` : null} 
      ${({ nowrap }) => nowrap ? css`white-space: nowrap;text-overflow: ellipsis; overflow: hidden;` : null} 
      ${({ column }) => column ? css`flex-direction: column;` : null} 
      ${({ row }) => row ? css`fdlex-direction: row;` : null} 
      ${({ justifyContent }) => justifyContent ? css`justify-content: ${justifyContent};` : null} 
      ${({ alignItems }) => alignItems ? css`align-items: ${alignItems};` : null} 
      ${({ color, theme }) => color ? css`color: ${color};` : `color: ${theme.defaultContentColor};`} 
      ${({ width }) => width ? css`width: ${width};` : null} 
      ${({ height }) => height ? css`height: ${height};` : null} 
      ${({ lineHeight }) => lineHeight ? css`line-height: ${lineHeight};` : null} 
      ${({ divider }) => divider ? css`border-bottom: 1px solid #ced6e7;` : null} 
      ${({ border }) => border ? css`border: 1px solid #ced6e7;` : null} 
      ${({ borderBottom }) => borderBottom ? css`border-bottom: 1px solid #ced6e7;` : null} 
      ${({ borderTop }) => borderTop ? css`border-top: ${borderTop};` : null} 
      ${({ borderLeft }) => borderLeft ? css`border-left: ${borderLeft};` : null} 
      ${({ borderRadius }) => borderRadius ? css`border-radius: ${borderRadius};` : null} 
      ${({ textAlign }) => textAlign ? css`text-align: ${textAlign};` : null} 
      ${({ maxWidth }) => maxWidth ? css`max-width: ${maxWidth};` : null} 
      ${({ minWidth }) => minWidth ? css`min-width: ${minWidth};` : null} 
      ${({ minHeight }) => minHeight ? css`min-height: ${minHeight};` : null} 
      ${({ maxHeight }) => maxHeight ? css`max-height: ${maxHeight};` : null} 
      ${({ pointer }) => pointer ? css`cursor: pointer;` : null} 
      ${({ flex1 }) => flex1 ? css`flex: ${flex1};` : null} 
      ${({ overflow }) => overflow ? css`overflow: ${overflow};` : null} 
      ${({ overflowX }) => overflowX ? css`overflow-x: ${overflowX};` : null} 
      ${({ margin }) => margin ? css`margin: ${margin};` : null} 
      ${({ m }) => m ? css`margin: ${m};` : null} 
      ${({ padding }) => padding ? css`padding: ${padding};` : null} 
      ${({ p }) => p ? css`padding: ${p};` : null} 
      ${({ display }) => display ? css`display: ${display};` : null} 
      ${({ position }) => position ? css`position: ${position};` : null} 
      ${({ top }) => (top || top === 0) ? css`top: ${top};` : null} 
      ${({ bottom }) => (bottom || bottom === 0) ? css`bottom: ${bottom};` : null} 
      ${({ left }) => (left || left === 0) ? css`left: ${left};` : null} 
      ${({ right }) => (right || right === 0) ? css`right: ${right};` : null} 
      ${({ zIndex }) => zIndex ? css`z-index: ${zIndex};` : null} 
      ${({ gridGap }) => gridGap ? css`grid-gap: ${gridGap};` : null} 
      ${({ gridTemplateColumns }) => gridTemplateColumns ? css`grid-template-columns: ${gridTemplateColumns};` : null} 
      ${({ gridTemplateRows }) => gridTemplateRows ? css`grid-template-rows: ${gridTemplateRows};` : null} 
      ${({ boxSizing }) => boxSizing ? css`box-sizing: ${boxSizing};` : null} 
      ${({ gridAutoFlow }) => gridAutoFlow ? css`grid-auto-flow: ${gridAutoFlow};` : null} 
      ${({ alignSelf }) => alignSelf ? css`align-self: ${alignSelf};` : null} 
      ${({ style }) => style ? css`${style}` : null} 
      ${({ active }) => active ? css`font-weight: 600; color: ${({ theme }) => (theme.active)};` : null}
      ${({ responsive }) => responsive ? css`${responsive};` : null}
      ${({ ifMobile }) => ifMobile ? css`${ifMobile};` : null}
      ${({ opacity }) => opacity ? css`opacity: ${opacity};` : null}
      ${({ transform }) => transform ? css`transform: ${transform};` : null}
      ${({ transformOrigin }) => transformOrigin ? css`transform-origin: ${transformOrigin};` : null}
      ${({ breakWord }) => breakWord ? css`word-break: break-word;` : null}
      ${({ clipPath }) => clipPath ? css`clip-path: ${clipPath};` : null}
      ${({ letterSpacing }) => letterSpacing ? css`letter-spacing: ${letterSpacing};` : null}
      ${({ textOverflow }) => textOverflow ? css`text-overflow: ${textOverflow};` : null}

      ${({ transition }) => transition ? css`transition: ${transition};` : 'transition: all linear 0.2s;'} 
       
     ${({ hover }) => hover ? css`
     :hover{
        ${hover}
     };
     ` : null} 

`

const StyledContainer = (props) => {
    return (
        <Container {...props}>
            {props.children}
        </Container>
    );
};

export default StyledContainer;
