import React, { useMemo } from "react";
import {
  basicBlue,
  basicGrey,
  darkBlue,
  Flex,
  StyledA,
  StyledLink,
  Text,
} from "../../style";
import StyledContainer from "../../style/styledContainer";
import { HiArrowRight } from "react-icons/hi";
import { useRef, useState } from "react";
import { InView, useInView } from "react-intersection-observer";
import { Controller, Scene } from "react-scrollmagic";
import ScrollBar from "../ScrollBar";
import { useSize } from "ahooks";

const roadMap = [
  {
    title: "Setup a secure wallet in an instant",
    des: "Worried about security? Then worry no more. The DCRM tech behind the Chainge app is naturally immune to hacks while your wallet’s seed phrase can easily be encrypted in a QR code you can keep literally anywhere since you’re the only one who can decrypt it. ",
    buttonDes: "Learn more about our security features:",
    buttons: [
      {
        title: "Expert mode vs beginner mode",
        link: "https://chainge-finance.medium.com/beginner-vs-expert-mode-in-the-chainge-app-security-and-faqs-fbae322f92ba",
      },
      {
        title: "Bullet proof security",
        link: "https://chainge-finance.medium.com/how-does-chainge-finance-guarantee-100-security-42464b0876db",
      },
    ],
  },
  {
    title: "Chain-hop with a couple of taps",
    des: "Your assets are split between multiple chains? Well, that doesn’t matter anymore. We deal in universal assets - which means in the Chainge app you have the freedom of performing cross-chain transactions and moving your assets from one chain to another within seconds. ",
    buttonDes: "Learn more about our cross-chain roaming feature:",
    buttons: [
      {
        title: "Taking cross-chain to new heights",
        link: "https://chainge-finance.medium.com/taking-cross-chain-to-new-heights-why-is-our-tech-the-best-how-it-works-e601371be380",
      },
      {
        title: "Watch video1",
        link: "https://www.youtube.com/watch?v=bWLTgE5KmQ8",
      },
      {
        title: "Watch video2",
        link: "https://www.youtube.com/watch?v=k7W0s7GcEaM&t=1s",
      },
    ],
  },
  {
    title: "More than staking",
    des: "Time-Framing, (aka Staking on steroids) is a unique Chainge functionality that allows you to split your asset into defined time-periods. This basically means you are assigning a purpose to a certain amount of wealth for a certain amount of time, while still having full ownership rights over your assets.",
    buttonDes: "Learn more about our time-framing feature:",
    buttons: [
      {
        title: "Earning in the best possible way",
        link: "https://chainge-finance.medium.com/earning-in-the-best-possible-way-time-framing-is-here-to-stay-657b2affffe1",
      },
      {
        title: "Watch video1",
        link: "https://youtu.be/c-9G53hWMsg",
      },
      {
        title: "Watch video2",
        link: "https://youtu.be/m9O59BRLJ_s",
      },
      {
        title: "Watch video3",
        link: "https://youtu.be/YvesyV8uQ8g",
      },
    ],
  },
  {
    title: "Make money while you sleep",
    des: "Why leave your assets laying around in your wallet when they can generate some serious passive income? The Chainge DEX LPs offer great APYs, 100% flexibility + security. It doesn’t get any better than this.",
    buttonDes: "Learn more about the Chainge liquidity pools:",
    buttons: [
      {
        title: "Add liquidity in Chainge pools",
        link: "https://chainge-finance.medium.com/add-liquidity-in-the-chainge-dex-pools-and-fill-your-wallets-8385622a4528",
      },
      {
        title: "Risk free passive income",
        link: "https://chainge-finance.medium.com/2-risk-free-ways-to-get-passive-income-time-framing-futures-liquidity-pools-ab464b2bca17",
      },
      {
        title: "Watch video",
        link: "https://www.youtube.com/watch?v=MZMaeu0V6Ik",
      },
    ],
  },
  {
    title: "Work that exchange trilogy",
    des: "From atomic swaps to Futures trading and writing Options, we have ‘em all. Exchange your Spot assets in an instant, leverage Futures or Options and enjoy the perks & freedom of a 100% decentralized derivatives market.",
    buttonDes: "Learn more about the Chainge DEX Trilogy:",
    buttons: [
      {
        title: "Meet the future of DeFi",
        link: "https://chainge-finance.medium.com/the-chainge-dex-trilogy-prepare-to-meet-the-future-of-defi-39ec903c7838",
      },
      {
        title: "Spot DEX & Futures DEX",
        link: "https://chainge-finance.medium.com/discover-the-chainge-dex-spot-and-futures-1314aee8ecf",
      },
      {
        title: "The Options DEX",
        link: "https://chainge-finance.medium.com/sky-rocket-your-profit-and-start-big-boy-trading-with-the-chainge-options-dex-6017ea1e02fe?source=user_profile---------2----------------------------",
      },
    ],
  },
  {
    title: "Decentralised escrow service",
    des: (
      <StyledContainer flex column>
        <Text>
          One of the most powerful financial instruments the world has now
          access to through Chainge is without a shadow of a doubt the
          decentralized escrow which replaces various kinds of traditional
          contracts.
        </Text>
        <br />
        <Text>
          You can buy or sell goods or services, trade crypto OTC and many more.
          All in the blink of an eye, while having the certainty that all
          contractual conditions will be met.
        </Text>
      </StyledContainer>
    ),
    buttonDes: "Learn more about the Decentralised Escrow Module:",
    buttons: [
      {
        title: "Crypto OTC",
        link: "https://youtu.be/3MOmftYjyXM",
      },
      {
        title: "Freelancers & online service providers",
        link: "https://youtu.be/WSH-GXJHaEU",
      },
      {
        title: "Buying/Selling online domains",
        link: "https://youtu.be/bLD1r5RSEHs",
      },
      {
        title: "International Commercial Trading",
        link: "https://youtu.be/KWSRlCuBn_s",
      },
    ],
  },
];

const SelfContainer = ({ data: i, index }) => {
  // const { ref, inView } = useInView({
  //     threshold: 0,
  //     root: document.getElementById('root')
  // });

  const size = useSize(document.querySelector("body"));
  const [inView, setInView] = useState(false);
  const rootMarginSize = useMemo(() => {
    if (size && size.height) {
      // console.log('size.height / 2 ', size.height / 2 + 'px')
      return (-1 * size.height) / 4 + "px";
    }
    return 0 + "px";
  }, [size]);
  // const _d = document.getElementById('root')
  return (
    <InView
      as="div"
      style={{ transition: "all linear .2s" }}
      rootMargin={rootMarginSize}
      threshold={0.5}
      onChange={(inView, entry) => setInView(inView)}
    >
      {/* <h2 style={{ position: 'fixed', top: 30 * index + 'px', right: 0 }}>{`Header${index} inside viewport ${inView}.`}</h2> */}
      <StyledContainer
        filter={`opacity(${inView ? "1" : ".5"})`}
        pb="50px"
        pt="50px"
        color="#fff"
      >
        <Text
          lineHeight="1"
          width="100%"
          maxWidth="388px"
          textAlign="left"
          fontSize="44px"
          fontFamily="LFTEticaSb"
        >
          {i?.title}
        </Text>
        <Text
          mt="15px"
          textAlign="left"
          maxWidth="498px"
          width="100%"
          fontFamily="LFTEtica"
          fontSize="16px"
        >
          {i?.des}
        </Text>
        <Text
          mt="20px"
          mb="10px"
          textAlign="left"
          fontSize="16px"
          fontFamily="LFTEticaSb"
        >
          {i?.buttonDes}
        </Text>
        {i?.buttons.map((j, index) => (
          <StyledA
            key={index}
            target="_blank"
            href={j.link}
            justifyContent="flex-start"
          >
            <Flex
              hover={`background: #fff; color: #3e4cbf;`}
              borderRadius="3px"
              alignItems="center"
              mb="5px"
              width="max-content"
              fontSize="14px"
              p="7px 15px"
              color={basicGrey}
              background={darkBlue}
              key={j.title}
            >
              <Text mr="10px" fontFamily="LFTEtica">
                {j?.title}
              </Text>
              <HiArrowRight></HiArrowRight>
            </Flex>
          </StyledA>
        ))}
      </StyledContainer>
    </InView>
  );
};

export default () => {
    const container = useRef(null);
    const _ = useSize(container);
    return (
      <div>
        <StyledContainer flex alignItems="center">
          <ScrollBar height={_?.height}></ScrollBar>
          <div ref={container}>
            <StyledContainer ml="70px">
              <div id="container1" style={{ paddingLeft: "-71px" }}>
                <Controller container="#container1">
                  <Scene duration={600} pin={true}>
                    <StyledContainer position="relative">
                      <div>
                        {roadMap.map((i, index) => (
                          <SelfContainer
                            key={i?.title}
                            index={index}
                            data={i}
                          ></SelfContainer>
                        ))}
                      </div>
                    </StyledContainer>
                  </Scene>
                </Controller>
              </div>
            </StyledContainer>
          </div>
        </StyledContainer>
      </div>
    );
  }
