import { basicGrey, Flex, Grid, StyledImg } from "../../style"
import StyledContainer from "../../style/styledContainer"
import btc from '../../assets/image/btc.svg'
import eth from '../../assets/image/eth.svg'
import fsn from '../../assets/image/fsn.svg'
import tron from '../../assets/image/tron.svg'
import bsc from '../../assets/image/bsc.svg'
import okex from '../../assets/image/okex.svg'
import avax from '../../assets/image/avax.svg'
import heco from '../../assets/image/heco.svg'
import arbitrum from '../../assets/image/arbitrum.svg'
import polygon from '../../assets/image/polygon.svg'
import filecoin from '../../assets/image/filecoin.svg'
import elastos from '../../assets/image/elastos.svg'
import ftm from '../../assets/image/ftm.svg'


const networkSupported = [
    btc, eth, fsn, tron, bsc, okex, avax, heco, arbitrum, polygon, filecoin, elastos, ftm
]
const len = networkSupported.length

export default () => {
    return (
        <Grid gridAutoFlow='row dense' gridGap='30px' gridTemplateColumns={`repeat(auto-fit, 30px)`} justifyContent='center' background={basicGrey} width='100%' p='35px 0'>
            {
                networkSupported.map((i, index) => (
                    <StyledImg width='30px' key={index} src={i}></StyledImg>
                ))
            }
        </Grid>
    )
}