import { Link } from 'react-router-dom'
import styled, {css} from 'styled-components'
import StyledContainer from './styledContainer'


export const lightYellow = '#ffda00'
export const basicBlack = '#121217'
export const basicWhite = '#f0f3f9'
export const basicBlue = '#4c5fef'
export const basicGrey = '#f0f3f9'
export const seniorGrey = '#95a3be'
export const darkBlue = '#3e4cbf'

export const FullScreenWidthSection = styled(StyledContainer)`
    width: 100vw;
`

export const FullScreenSection = styled(StyledContainer)`
    width: 100vw;
    height: 100vh;
`
export const Text = styled(StyledContainer)`
line-height: 1.3;
`

export const TextFs10 = styled(StyledContainer)`
font-size: 10px;
line-height: 1;
transform: scale(0.83);
`

export const Bold = styled(StyledContainer)`
    font-wight: bold;
`

export const Grid = styled(StyledContainer)`
    display: grid;
`

export const Flex = styled(StyledContainer)`
    display: flex;
`

export const Absolute = styled(StyledContainer)`
    position: absolute;
`

export const Column = styled(StyledContainer)`
    display: flex;
    flex-direction: column;
`
export const Row = styled(StyledContainer)`
    display: flex;
    flex-direction: row;
`
export const Center = styled(StyledContainer)`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%
`

export const ColCenter = styled(StyledContainer)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%
`

export const StyledA = styled.a`
    width: 100%;
    height: 100%;
    color: ${({color})=>(color || 'inherit')};
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: ${({justifyContent})=>(justifyContent || 'center')};
    font-family: LFTEticaSb;
    ${(borderRadius)=>borderRadius && (css`border-radius: ${borderRadius};`)}
`

export const StyledImg = styled.img`
    width: ${({ width }) => (width || '100%')};
// height: 100%;
    display: inline-block;
`

export const ShadowedText = styled(StyledContainer)`
position: relative;
text-align: center;
&::after{
    content: ${({ content }) => (`'${content}'`)};
    font-family: LFTEticaXB;
    display: inline-block;
    transform: translate(-50%, 0) scale(12);
    color: rgba(0,0,0,.3);
    top: -50px;
    left: 50%;
    position: absolute;
    white-space: nowrap;
    opacity: .1;
    font-weight: bolder;
}
`

export const StyledLink = styled(Link)``

export const StyledButton = styled(StyledContainer)`
    // width: 198px;
    // height: 40px;
    // margin: 30px 407px 0 194px;
    padding: 9px 15px;
    cursor: pointer;
    object-fit: contain;
    border-radius: 40px;
    background-color: #121217;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const User = styled(StyledContainer)`
  margin: 0px 15px 0px 0px;
  font-family: LFTEtica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  //line-height: 1;
  letter-spacing: normal;
  color: #121217;
  height: 20px;
  line-height: 20px;
  &::before {
    content: " ";
    left: 16px;
    top: 45%;
    border: 1px solid #bac4da;
    background-color:  #bac4da;
    display: inline-block;


    margin: 5px 6px 3px 0;
    
    width: 4px;
    height: 4px;
    border-radius: 50%;
    //margin-right: 12px;
  }
  

`