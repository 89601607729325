import { useMemo } from 'react'
import { useScroll, useSize } from "ahooks"
import styled from "styled-components"
import StyledContainer from "../../style/styledContainer"

const ContainerWithScrollBar = styled(StyledContainer)`
// height: 100%;
transition: all linear .1s;
&::after{
    content: '';
    display: inline-block;
    height: 150px;
    width: 100%;
    background: #fff;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(0, ${({ y }) => (y <= 0 ? 0 : y)}px);
    // top: ${({ top }) => (top)};
    transition: all linear .3s;
    width: 2px;
}
`

export default ({ height }) => {
    const container = document.getElementById('scroll-article')

    const scroll = useScroll(document);
    const _ = useSize(container)

    const scrollDis = useMemo(() => {
        if (container) {
            // 滚动条居中
            return (scroll?.top - container.offsetTop - 60 - 75 + document.body.offsetHeight/2 - 50)
        }
        return 0
    }, [height, scroll, container, _])

    return (<ContainerWithScrollBar height={height + 'px'} y={scrollDis || 0} position='relative' width='2px' background='#3e4cbf'   >

    </ContainerWithScrollBar>)
}