
import { Route, BrowserRouter as Router, Switch, useLocation } from 'react-router-dom';
import './App.css';
import Home from './view/Home';
import 'antd/dist/antd.css'

function App() {
  return (
    <div className="App">
      <Router>
        <Home></Home>
      </Router>
    </div>
  );
}

export default App;
