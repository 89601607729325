import React from 'react';
import poster from '../assets/image/poster.jpg'

// const mediaSource = new MediaSource();
// const video = document.createElement('video');
// try {
//   video.srcObject = mediaSource;
// } catch (error) {
//   video.src = URL.createObjectURL(mediaSource);
// }

export default () => {
  return (
    // <Reflv
    //     url={video}
    //     type="flv"
    //     isLive
    //     cors
    // />
    <video preload="load" style={{ width: '100%', height: '100%', display: 'inline-block', objectFit: 'cover' }} poster={poster} autoPlay muted loop>
      <source src={'https://website-open.s3.ap-southeast-1.amazonaws.com/site-video.mp4'}></source>
    </video>
  )
}