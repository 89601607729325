import StyledContainer from "../../style/styledContainer"
import ill from '../../assets/image/illustration@2x.png'
import styled from "styled-components"
import { Column, Absolute, StyledButton, Text, Flex } from "../../style"
import dots from '../../assets/image/dots.svg'
import { DiAndroid, DiApple } from 'react-icons/di'
import QRCode from 'qrcode.react'
import { Menu, Dropdown, Tooltip } from 'antd'

const QuarterCircle = styled(StyledContainer)`
width: 100px;
height: 100px;
border: 1px solid red;
border-radius: 50%;
-webkit-clip-path: circle(50% at 100% 0%);
background: red;
`

const Circle = styled(StyledContainer)`
width: ${({ size }) => (size + 'px')}; 
height: ${({ size }) => (size + 'px')}; 
background: ${({ color }) => (color || '#1c1d22')};
border-radius: 50%;
// -webkit-mask: radial-gradient(transparent, transparent 110px, #000 110px);
mask: radial-gradient(transparent ${({ arc }) => (arc + 'px')}, #000 ${({ arc }) => (arc + 'px')});
transition: clip-path .2s;
`
const googleMenu = (
    <Menu style={{ position: 'relative', zIndex: '3' }}>
        <Menu.Item>
            <QRCode level='M' value="https://play.google.com/store/apps/details?id=com.fusion.chainge.android" />
        </Menu.Item>
    </Menu>
)
const iosMenu = (
    <Menu style={{ position: 'relative', zIndex: '3'}}>
        <Menu.Item>
            <QRCode level='M' value="https://apps.apple.com/cn/app/id1578987516" />
        </Menu.Item>
    </Menu>
)

export default () => {
    return (
        <StyledContainer position='relative' width='100%' maxWidth='1170px' m='auto' height='500px' background='#26272e'>
            <StyledContainer overflow='hidden' position='relative' width='100%' height='100%' >
                <Column mt='70px' ml='70px' position='relative' zIndex='3' alignItems='flex-start'>
                    <Text textAlign='left' fontSize='50px' fontFamily='LFTEticaLt' color='#fff' lineHeight='1.1'>
                        Download the Chainge <br />
                        app and go full DeFi
                    </Text>
                    <Flex mt='40px'>
                        {/* <Tooltip placement="bottom" title={<QRCode style={{position: 'relative', zIndex: 3}} level='M' value="https://apps.apple.com/cn/app/id1578987516" />}> */}
                        <Dropdown overlay={iosMenu} placement="bottomCenter">
                            <StyledButton >
                                <DiApple size='22px'></DiApple>
                                <Text ml='10px' fontFamily='LFTEticaSb' fontSize='16px'>From Appstore</Text>
                            </StyledButton>
                        </Dropdown>
                        {/* </Tooltip> */}
                        {/* <Tooltip placement="bottom" title={<QRCode style={{position: 'relative', zIndex: 3}} level='M' value="https://play.google.com/store/apps/details?id=com.fusion.chainge.android" />}> */}
                        <Dropdown overlay={googleMenu} placement="bottomCenter">
                            <StyledButton ml='15px'>
                                <DiAndroid size='22px'></DiAndroid>
                                <Text ml='10px' fontFamily='LFTEticaSb' fontSize='16px'>From Google Play</Text>
                            </StyledButton>
                        </Dropdown>
                        {/* </Tooltip> */}
                    </Flex>
                </Column>

                <Absolute bottom='-250px' left='-250px' zIndex='2'>
                    <Circle size='490' arc='110'></Circle>
                </Absolute>
                <Absolute top='-150px' right='-150px' zIndex='2'>
                    <Circle size='300' arc='70'></Circle>
                </Absolute>

                <Absolute bottom='30px' right='30px'>
                    <Absolute bottom='20px' left='42px' zIndex='2'>
                        <Circle size='30' arc='6' color='#ffda00'></Circle>
                    </Absolute>
                    <Absolute right='243px' top='-10px' zIndex='2'>
                        <Circle size='42' arc='10' color='#ff464b'></Circle>
                    </Absolute>
                    <StyledContainer filter='opacity(0.2) brightness(0)' width='573px' height='237px' background={`url(${dots})`}></StyledContainer>
                </Absolute>
            </StyledContainer>
            <Absolute right='0' bottom='0' transform='translateY(50%)'>
                <img style={{ maxWidth: '419px' }} src={ill}></img>
            </Absolute>

        </StyledContainer >

    )
}