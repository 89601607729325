import styled, { keyframes } from 'styled-components'

const slideDown = keyframes`
  from {
    transform: translateY(15%);
    opacity: .7;
  }
  to {
    transform: translateY(70%);
    opacity: 0;
  }
`

const StyledRect = styled.rect`
  animation: 3s ${slideDown} linear infinite;
`

export default () => {
    return (
        <svg width="24" height="42" viewBox="0 0 24 42" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(1 1)" fill="none" fillRule="evenodd">
                <rect stroke="#FFF" strokeWidth="2" width="22" height="40" rx="10.5" />
                <StyledRect fill="#FFF" x="9" y="0" width="4" height="8" rx="2" />
            </g>
        </svg>

    )
}