import { useRef } from 'react'
import styled from "styled-components"
import { Bold, Text, Flex, Column, lightYellow } from "../../style"
import StyledContainer from "../../style/styledContainer"
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { useSize } from 'ahooks'

import SwiperCore, {
    Autoplay
} from 'swiper';

SwiperCore.use([Autoplay]);

const StyledSwiper = styled(Swiper)`
& .swiper-wrapper{
    display: flex;
    flex-direction: column;
}
`

const SectionWithBottomLine = styled(Text)`
    :after{
        position: absolute;
        right: 0;
        content: '';
        display: block;
        width: 500%;
        background: ${lightYellow};
        height: 5px;
    }
`

export default () => {

    const container = useRef(null)
    const size = useSize(container)

    return (

        <Flex width='100%' justifyContent='center' alignItems='center' fontSize='4.861111111111112vw' height='145px' position='relative' zIndex='1'>
            <div ref={container}>
                <SectionWithBottomLine position='relative' mr='25px'>BEYOND</SectionWithBottomLine>
            </div>
            <Bold fontFamily='LFTEticaXB' position='relative' zIndex='3'>
                <StyledSwiper
                    allowTouchMove={false}
                    loop
                    height={size?.height}
                    // spaceBetween={50}
                    autoHeight={true}
                    direction={'vertical'}
                    autoplay={{
                        "delay": 2000,
                    }}
                >
                    <SwiperSlide style={{ display: 'flex', alignItems: 'center', textAlign: 'left', textTransform: 'uppercase' }}>CROSS-CHAIN</SwiperSlide>
                    <SwiperSlide style={{ display: 'flex', alignItems: 'center', textAlign: 'left', textTransform: 'uppercase' }}>Trading</SwiperSlide>
                    <SwiperSlide style={{ display: 'flex', alignItems: 'center', textAlign: 'left', textTransform: 'uppercase' }}>Derivatives</SwiperSlide>
                    <SwiperSlide style={{ display: 'flex', alignItems: 'center', textAlign: 'left', textTransform: 'uppercase' }}>DeFi</SwiperSlide>
                </StyledSwiper>
            </Bold>
        </Flex>
    )
}